import { render, staticRenderFns } from "./narrow.vue?vue&type=template&id=4782b37f&lang=pug&"
import script from "./narrow.vue?vue&type=script&lang=js&"
export * from "./narrow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/codebuild/output/src2376445070/src/frontend/components/Header.vue').default,Body: require('/codebuild/output/src2376445070/src/frontend/components/Body.vue').default,Footer: require('/codebuild/output/src2376445070/src/frontend/components/Footer.vue').default})
