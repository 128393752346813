import { render, staticRenderFns } from "./SurveyView.vue?vue&type=template&id=f08f0762&scoped=true&lang=pug&"
import script from "./SurveyView.vue?vue&type=script&lang=js&"
export * from "./SurveyView.vue?vue&type=script&lang=js&"
import style0 from "./SurveyView.vue?vue&type=style&index=0&id=f08f0762&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f08f0762",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NoCrmClientAlert: require('/codebuild/output/src2376445070/src/frontend/components/NoCrmClientAlert.vue').default,InactiveClientAlert: require('/codebuild/output/src2376445070/src/frontend/components/InactiveClientAlert.vue').default,SurveyHeader: require('/codebuild/output/src2376445070/src/frontend/components/survey/SurveyHeader.vue').default,SurveyFooter: require('/codebuild/output/src2376445070/src/frontend/components/survey/SurveyFooter.vue').default})
