import { render, staticRenderFns } from "./AdditionalCoveraveVideo.vue?vue&type=template&id=7f6c6bdf&lang=pug&"
import script from "./AdditionalCoveraveVideo.vue?vue&type=script&lang=js&"
export * from "./AdditionalCoveraveVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Video: require('/codebuild/output/src2376445070/src/frontend/components/Video.vue').default})
