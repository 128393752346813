import { render, staticRenderFns } from "./CurrentCoverageChoices.vue?vue&type=template&id=ed8f1192&scoped=true&lang=pug&"
import script from "./CurrentCoverageChoices.vue?vue&type=script&lang=js&"
export * from "./CurrentCoverageChoices.vue?vue&type=script&lang=js&"
import style0 from "./CurrentCoverageChoices.vue?vue&type=style&index=0&id=ed8f1192&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed8f1192",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src2376445070/src/frontend/components/Icon.vue').default})
