import { render, staticRenderFns } from "./Plans.vue?vue&type=template&id=3d01be42&lang=pug&"
import script from "./Plans.vue?vue&type=script&lang=js&"
export * from "./Plans.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src2376445070/src/frontend/components/Icon.vue').default,PlanCard: require('/codebuild/output/src2376445070/src/frontend/components/PlanCard.vue').default,Spinner: require('/codebuild/output/src2376445070/src/frontend/components/Spinner.vue').default})
